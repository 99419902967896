.auth-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    min-height: 560px;
    height: 560px;

    border-radius: 4px;
    overflow: hidden;

    background-color: transparent;
    box-shadow: var(--default-box-shadow);

    .logo-col {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        border-radius: 4px;
        padding: 40px;

        .logo-container {
            max-width: 400px;
        }

        &.logo-col-small {
            padding: 20px;

            .logo-container {
                max-width: 120px;
            }
        }
    }

    .form-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        height: 100%;
        max-height: 100%;

        overflow: hidden;

        background-color: white;
    }

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: stretch;

        height: auto;
        width: 100%;
        max-width: 480px;

        .logo-col.logo-col-small {
            .logo-container {
                max-width: 200px;
            }
        }

        .ant-col {
            width: 100%;
            max-width: 100%;
            flex: 0 0 auto;
        }
    }
}
