:root {
    --default-text-color: #363a45;
    // --default-background-gradient: transparent
    //   linear-gradient(180deg, #3e7abf 0%, #00b0ff 100%) 0% 0% no-repeat
    //   padding-box;
    --default-background-color: #00b0ff;
    --default-background-color-alt: #3e7abf;

    --default-box-shadow: 0px 3px 6px #00000029;

    --color-success: #108e53;
    --color-success-hover: #2a9c63;
    --color-success-active: #06693e;
}

body {
    @font-face {
        font-family: 'Open Sans';
        src: url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')
            format('truetype');
    }
    font-family: 'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji';

    margin: 0;
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .ant-form-item-explain-error,
    .ant-form-item-explain-warning {
        max-width: fit-content;
    }

    .error-alert {
        overflow: auto;
        margin: 24px 0px;
        border-radius: 5px;

        .ant-alert-message {
            font-size: 15px;
        }
        .ant-alert-description {
            font-size: 13px;
        }
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.ant-btn-loading-icon {
    margin-right: 10px;
}

.ant-breadcrumb {
    a:hover {
        color: #ffd354 !important;
        filter: contrast(1.1) brightness(0.9);
    }
}

// .ant-layout-sider-children > * .ant-menu-item:hover {
//     background-color: rgba(255, 255, 255, 0.12);
// }
